#services {
    padding: 4rem 0;
    background-color: #FCFBF7;
    display: flex;
    align-items: flex-start; /* Ensure header aligns with the top */
    position: relative;
  }
  
  #services h2 {
    writing-mode: vertical-lr;
    text-orientation: mixed;
    transform: rotate(180deg);
    position: relative; /* Keep header in a fixed position */
   
  }
  
  #service-img {
    width: 30%; /* Prevent image from stretching */
    max-width: 600px;
    height: auto; /* Ensure the image maintains its aspect ratio */
    object-fit: cover; /* Prevent any distortion */
  }

  #service-img-mobile {
    width: 65%; /* Prevent image from stretching */
    object-fit: cover; /* Prevent any distortion */
  }
  
  #service-container {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    width: 100%; /* Ensure the container takes up available width */
    align-items: flex-start; /* Align content at the top */
  }
  
  .service-btn {
    background-color: unset;
    border: unset;
    padding: 10px 20px 10px 0;
    font-family: 'Kamerik', sans-serif;
    color: #313131;
    opacity: 0.3;
    font-size: 22px;
  }
  
  .service-btn-selected {
    background-color: unset;
    border: unset;
    color: #313131;
    padding: 10px 20px 10px 0;
    font-family: 'Kamerik', sans-serif;
    font-size: 22px;
  }
  
  .accordion {
    width: 50vw; /* Adjust width to prevent it from affecting image space */
  }
  
  .accordion-header {
    padding: 20px 0;
    border-bottom: 1px solid black;
    color: #9D7A68;
    font-family: 'Kamerik', sans-serif;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .accordion-header:hover {
    cursor: pointer;
  }
  
  .arrow-open {
    height: 15px;
    transition: 0.3s ease-in-out;
  }
  
  .arrow-closed {
    height: 15px;
    transform: rotate(-90deg);
    transition: 0.3s ease-in-out;
  }
  
  /* Accordion Body */
  .accordion-body {
    overflow: hidden;
    max-height: 0;
    transition: 0.5s ease-in-out;
  }
  
  .accordion-body.open {
    max-height: 2000px;
    padding: 10px 0;
    opacity: 1;
  }
  
  .accordion-body.collapsed {
    max-height: 0;
    padding: 1px;
    opacity: 0;
  }
  
  @media only screen and (max-width: 750px) {
    #services {
      justify-content: space-between;
      flex-wrap: wrap;

    }

    .accordion {
      width: 100%; 
    }
  }
  