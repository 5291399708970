#about{
    background-color: #EDEAE3;
    padding: 70px 0;
}

#about-img{
    width: 40%;
    max-width: 700px;
    object-fit: cover;
    
}

#about-container{
    display: flex;
}

#about-info{
    max-width: 700px;
    padding: 2rem;
}

@media only screen and (max-width: 750px) {
    #about{
        padding: 0;
    }
  }
  