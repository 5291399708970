#team{
    background-color: #F5F5F5;
    padding: 2rem;
}

#team-container{
    width: 1400px;
    max-width: 100%;
    margin: 0 auto;
}

.staff-img{
    width: 31%;
}

@media only screen and (max-width: 750px) {
    .staff-img{
        width: 48%;
    }
    #team{
        padding: 1rem;
    }
}