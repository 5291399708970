.modal{
    height: 100vh;
    width: 100vw;
    background-color: rgba(17,17,17,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
}

.modal-container{
    position: relative;
    width: 90vw;
    max-width: 550px;
    max-height: 90vh;
    background-color: #FCFBF7;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;
    min-height: 500px;
}

.booking-select{
    height: 44px;
    background-color: unset;
    margin: 10px 0;
    padding: 0 10px;
    border: 1px solid #313131;
    color: #313131;
}