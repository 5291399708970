footer{
    background-color: #D2B5A7;
    display: flex;
    justify-content: space-between;
    padding: 4rem 3rem;
    flex-wrap: wrap;
    
}

.address-line{
    color: white;
    font-family: 'Kamerik', sans-serif;
    font-size: 22px;
}

#footer-right{
    text-align: right;
    display: flex;
    flex-direction: column;
    color: white;
}

.social{
    height: 40px;
    width: 40px;
    margin: 0 7px;
}


@media only screen and (max-width: 750px) {
    footer{
        justify-content: center;
    }
    .address-line,
    #footer-right{
        text-align: center;
    }
    #footer-right{
        margin-top: 20px;
    }
}