#salon{
    background-color: #FCFBF7;
    padding: 2rem;
}

#salon-container{
    width: 1400px;
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;
}

#gallery{
    display: flex;
    overflow: scroll;
    scroll-behavior: smooth; 
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

#gallery::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and WebKit-based browsers */
  }

.gallery-img{
    height: 70vh;
    max-height: 650px;
    margin-right: 20px;
}

#scroll-container{
    width: 100%;
    height: 10px;
    background-color: #9D7A6840;
    margin-top: 30px;
    position: relative;
    z-index: 1;
}


#scroll-percentage{
    height: 10px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #9D7A68;
    z-index: 999;
    opacity: 1;
}

@media only screen and (max-width: 750px) {
    .gallery-img{
        height: 40vh;
        max-height: 650px;
    }    
}