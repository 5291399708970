#contact{
    background-color: #EDEAE3;
    padding: 4rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#contact h2{
    writing-mode: vertical-lr;
    text-orientation: mixed;
    transform: rotate(180deg);
}

#contact-img{
    width: 30%;
    max-width: 600px;
    margin-left: 30px;
}

form input {
    border: unset;
    margin: 5px 0;
    height: 48px;
    font-size: 16px;
    padding: 0 1rem;
}


form textarea {
    border: unset;
    margin: 5px 0;
    height: 148px;
    resize: none;
    padding: 1rem;
    font-size: 16px;

}

form{
    width: 450px;
    max-width: 100%;
    margin: 2rem auto;
}

form input::placeholder, form textarea::placeholder{
    opacity: 0.5;
    font-family: "Kamerik", sans-serif;
    font-size: 16px;

}

@media only screen and (max-width: 750px) {
    #contact-img{
        width: 65%;
    }

    #contact{
        flex-wrap: wrap;
        padding: 4rem 0 2rem 0;

  }
}
  