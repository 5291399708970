header{
    width: 100%;
    height: 76px;
    background-color: #9D7A68;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
}

nav a {
    color: white;
    margin: 0 15px;
    font-family: 'Kamerik', sans-serif;
    font-size: 18px;
    font-weight: 400;

}

#nav-logo{
    height: 48px;

}

nav{
    display: flex;
    align-items: center;
}

.mobile-nav-content{
    display: none;
}

@media only screen and (max-width: 750px) {
    header{
        padding: 0 1rem;
    }
    .mobile-nav-content{
        display: flex;
        align-items: center;
    }
}