#hero{
    background-image: url('../Assets/images/hero.webp');
    background-position: left;
    background-size: cover;
    width: 100%;
    height: calc(100vh - 94px);
    min-height: 600px;
    display: flex;
    align-items: center;
    position: relative;
}

#hero-info{
    width: 1400px;
    max-width: 100%;
    padding: 2rem;
    margin: 0 auto;
    z-index: 3;
}

#overlay{
    position: absolute;
    background-color: rgba(17, 17, 17, 0.3);
    height: 100%;
    width: 100%;
}