.App {
  padding: 18px;
  position: relative;
  z-index: 1;
}

*{
  margin: 0;
  box-sizing: border-box;
  font-family: "Ruda", sans-serif;
}

.white-btn{
  height: 36px;
  border-radius: 18px;
  border: 1px solid white;
  color: white;
  background-color: unset;
  padding: 0px 20px;
  font-size: 16px;
}

.white-solid{
  height: 36px;
  border-radius: 18px;
  border: 1px solid white;
  color: #313131;
  background-color: white;
  padding: 0px 20px;
  font-size: 16px;
}

.solid-black-btn{
  height: 36px;
  border-radius: 18px;
  border: 1px solid white;
  color: #fff;
  background-color: #313131;
  padding: 0px 20px;
  font-size: 16px;
}

.black-btn{
  height: 36px;
  border-radius: 18px;
  border: 1px solid black;
  color: black;
  background-color: unset;
  padding: 0px 20px;
  font-size: 16px;
}

h2{
  font-size: 148px;
  font-weight: 400;
  font-family: 'Kamerik', sans-serif;
}

h3{
  font-size: 64px;
  font-weight: 400;
  font-family: 'Kamerik', sans-serif;
}

button:hover, a:hover{
  cursor: pointer;
}

p{
  line-height: 1.5;
}

.mobile{
  display: none;
}

@font-face {
  font-family: 'Kamerik';
  src: local('Kamerik'), url(./Assets/fonts/Kamerik.ttf) format('truetype');
}

@media only screen and (max-width: 750px) {
  .mobile-remove{
    display: none;
  }
  .mobile{
    display: block;
  }
 
  h2{
    font-size: 20vw;
  }

  h3{
    font-size: 13vw;
  }
  
  .App {
    padding: 0;
  }
}